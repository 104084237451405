import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { secondDraftData } from '../../data/SecondDraftData';
import styles from './SecondDraft.module.scss';

const SecondDraft = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6500,
    speed: 1700,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className={styles.fd}>
      <div className={styles.sliderContainer}
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-delay="500"
      data-aos-once="true"
      >
        <Slider {...settings}>
          {secondDraftData.map((slide) => (
            <div key={slide.title}>
              <img src={slide.image} alt={slide.title} />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default SecondDraft;