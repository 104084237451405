import React from "react";
import Heroic from "../modules/Heroic.js";
import InfoSection from "../modules/InfoSection.js";
import { InfoData, InfoDataTwo } from "../data/InfoData.js";
import Listings from "../modules/Listings.js";
import HowWeWorkSection from '../modules/HowWeWorkSection'
import { SliderData } from "../data/SliderData.js";

const Home = () => {
	return (
		<>
			<Heroic slides={SliderData} />
			<InfoSection {...InfoData} />
			<Listings />
			<HowWeWorkSection />
			<InfoSection {...InfoDataTwo} />
		</>
	);
};

export default Home;