import ImageOne from "../../images/IntroImages/Design.webp";
import ImageTwo from "../../images/IntroImages/Design2.webp";
import ImageThree from "../../images/IntroImages/Design4.webp";
import ImageFour from "../../images/IntroImages/Design3.webp";

export const SliderData = [
	{
		title: "Visio 3D Design",
		price: "$69,000",
		path: "/projects",
		label: "Viev design",
		image: ImageOne,
		alt: "Room",
	},
	{
		title: "Visio 3D Design",
		// price: '$29,000',
		path: "/projects",
		label: "Viev design",
		image: ImageTwo,
		alt: "Room",
	},
	{
		title: "Visio 3D Design",
		// price: '$39,000',
		path: "/projects",
		label: "Viev design",
		image: ImageThree,
		alt: "Room",
	},
	{
		title: "Visio 3D Design",
		// price: '$109,000',
		path: "/projects",
		label: "Viev design",
		image: ImageFour,
		alt: "Room",
	},
];