import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: 'Montserrat', sans-serif;
	}

	html, body {
		overflow-x: hidden;
	}

	::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #181616;
}

::-webkit-scrollbar-thumb {
    background: #CD853F;
    border-radius: 8px;
}
`;

export default GlobalStyle;