import React from "react";
import styled, { css } from "styled-components/macro";
import { Button } from "./Button.js";
import { FaInstagram, FaTelegram } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

const Section = styled.section`
	background-color: #0f0d0c;
  	color: white;
 	width: 100%;
  	min-height: 400px;
	padding: 1rem calc((100vw - 1300px) / 2);
`;


const Container = styled.div`
	height: 100%;
	width: 100%;
	padding: 3rem 0rem 1rem 0rem;
`;

const FooterTop = styled.div`
	display: flex;
	flex-direction: row;
	padding: 0rem 0rem 0rem 0rem;

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
`;

const FooterInfo = styled.div`
	padding: 1rem;
	line-height: 3;
	display: flex;
	flex-direction: column;
	text-align: right;
	font-size: 10px;

	@media screen and (max-width: 768px) {
		padding: 1rem 0rem;
		text-align: left;
	}

	ul {
		list-style-type: none;
	}


	li {
		transition: 0.5s;
	}

	li:hover {
		color: white;
		cursor: text;
	}
`;

const FooterBottom = styled.div`
	display: flex;
	flex-direction: row-reverse;
	padding: 2rem 0rem;

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
`;

const SocialIcons = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 70%;
	line-height: 20px;
	margin-right: 15px;
	color: gray;

	@media screen and (max-width: 768px) {
		justify-content: flex-start;
		margin-bottom: 2rem;
		width: 100%;
	}
`;

const Icons = css`
	font-size: clamp(2rem, 4vw, 0rem);
	color: white;
`;

const Instagram = styled(FaInstagram)`
	${Icons};
	margin-left: 5px;
`;

const Telegram = styled(FaTelegram)`
	${Icons};
	height: 25px;
	width: 25px;
	transition: 0.5s;
	margin: 0 0 0 10px;

	:hover {
		transition: all 0.5s ease-in-out;
		transform: scale(1.2);
	}
`;

const Gmail = styled(SiGmail)`
	${Icons};
	height: 32px;
	width: 32px;
	margin-left: 7px;
`;

const Contact = styled.div`
	width: 50%;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	@media screen and (max-width: 768px) {
		width: 100%;
		justify-content: flex-start;
	}
`;

const Quote = styled.div`
	flex: 1;
	padding: 0rem 0rem 0rem 0rem;

	p {
		font-weight: normal;
		font-size: 18px;
		letter-spacing: 1px;
		color: #CD853F;
		margin: 5px 0 0 0px;

		@media screen and (max-width: 600px) {
			margin: 20px 0 -10px 0px;
		}

		@media screen and (max-width: 500px) {
			font-size: 14px;
		}
	}

	span {
		display: block !important;
		width: 1000px;
		font-weight: normal;
		font-size: 18px;
		letter-spacing: 1.2px;
		line-height: 2;
		padding: 0 0 0 0;
		margin: 0 0 70px 0;

		@media screen and (max-width: 1250px) {
			width: 600px;
		}
	}

	a {
		color: white;
	}

	@media screen and (max-width: 1360px) {
		padding: 0 50px;
	}
`;

const Footer = () => {
	return (
		<Section>
			<Container>
				<FooterTop>
					<Quote>
						<h3>
							<span>
								Don't hesitate to get in touch with us to turn your visions into stunning reality.
								<br />We're excited to collaborate with you and craft the perfect solution for your needs. 
								<br />Contact us today.
							</span>
							<br />
							<p>+373 602 64 093
								<a href="https://t.me/Dim125v"
									rel="noopener noreferrer"
									target="_blank"
								>
									<Telegram />
								</a>
							</p>
							<p>+380 660 423 938
								<a href="https://t.me/aryanesj"
									rel="noopener noreferrer"
									target="_blank"
								>
									<Telegram />
								</a>
							</p>
						</h3>
					</Quote>
					<FooterInfo>
					</FooterInfo>
					<FooterInfo>
						<h2 style={{ letterSpacing: "1	px", color: "#CD853F" }}>
							<ul>
								<li>3D-Modeling</li>
								<li>Interior design</li>
								<li>Space planning</li>
								<li>Visualization services</li>
								<li>Architectural rendering</li>
							</ul>
						</h2>
					</FooterInfo>
				</FooterTop>
				<FooterBottom>
					<SocialIcons>
						{" "}
						<h2 style={{ fontSize: "10px", margin: "0 0 0 0" }}>
							© 2023 <span>3D Roomify</span>

							<br />All rights reserved.{" "}
						</h2>
					</SocialIcons>
					<Contact>
						<Button
							to="/"
							target="_blank"
							rel="noopener noreferrer"
							style={{ width: "1%", fontWeight: "bold", marginRight: "15px" }}
						>
							<Instagram />
						</Button>
						<a href="mailto:byo125@gmail.com"
							rel="noopener noreferrer"
							target="_blank"
						>
							<Gmail />
						</a>
					</Contact>
				</FooterBottom>
			</Container>
		</Section>
	);
};

export default Footer;