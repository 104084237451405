import React, { useState, useEffect, useLayoutEffect } from "react";
import Navbar from "./components/modules/Navbar.js";
import GlobalStyle from "./globalStyle.js";
import Dropdown from "./components/modules/Dropdown.js";
import Footer from "./components/modules/Footer.js";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./components/pages/Home.js";
import About from "./components/pages/About.js";
import Projects from "./components/pages/Projects.js";
import Contact from "./components/pages/Contact.js";
import Rentals from "./components/pages/Rentals.js";
import Firstdraft from "./components/pages/FirstDraft.js";
import Seconddraft from "./components/pages/SecondDraft.js";
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  console.log(location.pathname);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // данный хук подымает скрол до упора при смене урла
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <>
      <GlobalStyle />
      <Navbar toggle={toggle} />
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/rentals" element={<Rentals />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/firstdraft" element={<Firstdraft />} />
        <Route path="/seconddraft" element={<Seconddraft />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;