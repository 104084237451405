import React from 'react'
import styles from './HowWeWorkSection.module.scss'

const HowWeWorkSection = () => {
    return (
        <section className={styles.hww}>
            <h2 className={styles.hww__title}>High quality textures and attention to details are<br />key components for a realistic render</h2>
            <div className={styles.hww__imgBlock}>
                <div className={styles.hww__colorBlock}
                    data-aos="fade-right"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                    data-aos-once="true"
                >
                    <p> 
                        Using our experience of more than 10 years in building and renovation, 
                        we pay attention to every detail<br />in creating our design and renders. 
                        So that the result will look perfect not only in final renders but also<br />in real life.<br /><br />
                        We are modeling carefully<br />every piece of the scene, from a small door handle to an entire building.
                    </p>
                </div>
            </div>
            {/* <button className={styles.hww__btn}>See more</button> */}
        </section>
    )
}

export default HowWeWorkSection