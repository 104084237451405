import img1 from '../../images/SecondDraftImages/1.webp'
import img2 from '../../images/SecondDraftImages/2.webp'
import img3 from '../../images/SecondDraftImages/3.webp'
import img4 from '../../images/SecondDraftImages/4.webp'
import img5 from '../../images/SecondDraftImages/5.webp'
import img6 from '../../images/SecondDraftImages/6.webp'
import img7 from '../../images/SecondDraftImages/7.webp'

export const secondDraftData = [
	{image: img1},
	{image: img2},
	{image: img3},
    {image: img4},
    {image: img5},
	{image: img6},
	{image: img7}
]