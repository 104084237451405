import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { menuData } from "../data/MenuData.js";
import { Button } from "./Button.js";
import { FaBars } from "react-icons/fa";

const Nav = styled.nav`
	height: 60px;
	display: flex;
	justify-content: space-between;
	padding: 1rem 2rem;
	z-index: 100;
	position: fixed;
	width: 100%;
`;

const NavLink = css`
	color: white;
	display: flex;
	align-items: center;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
	transition: 0.4s;
`;

const Logo = styled(Link)`
	${NavLink}
	letter-spacing: 2px;
`;

const MenuBars = styled(FaBars)`
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
		cursor: pointer;
		top: 0;
		right: 0;
		color: #fff;
	}
`;

const NavBtn = styled.div`
	display: flex;
	align-items: center;
	margin-right: 24px;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const NavMenu = styled.div`
	display: flex;
	align-items: center;
	margin-right: -48px;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const NavMenuLinks = styled(Link)`
	${NavLink}

	&:hover {
		-webkit-box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.35);
		-moz-box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.35);
		box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.35);
		border-radius: 4px;
	}

	&:active {
		transform: scale(0.98);
		transition: 0.1s;
	}
`;

const Navbar = ({ toggle }) => {
	const [navbar, setNavbar] = useState(false);
	const [showForm, setShowForm] = useState(false); // Добавляем состояние для отображения формы
	const location = useLocation();

	const changeBackground = () => {
		if (window.pageYOffset >= 610) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};

	useEffect(() => {
		const watchScroll = () => {
			window.addEventListener("scroll", changeBackground);
		};

		watchScroll();

		return () => {
			window.removeEventListener("scroll", changeBackground);
		};
	}, []);

	let style = {
		backgroundColor:
			navbar || location.pathname !== "/" ? "#CD853F" : "transparent",
		transition: "1s",
	};

	// Функция для открытия формы
	const openForm = () => {
		setShowForm(true);
	};

	// Функция для закрытия формы
	const closeForm = () => {
		setShowForm(false);
	};

	return (
		<Nav style={style}>
			<Logo
				to="/"
				data-aos="zoom-out"
				data-aos-delay="1000"
				data-aos-duration="2000"
			>
				3D Roomify
			</Logo>
			<MenuBars onClick={toggle} />
			<NavMenu>
				{menuData.map((item, index) => (
					<NavMenuLinks to={item.link} key={index}>
						{item.title}
					</NavMenuLinks>
				))}
			</NavMenu>
			<NavBtn>
				<Button onClick={openForm} primary="true">
					Send Form
				</Button>
			</NavBtn>
			{showForm && (
				<div
					style={{
						position: "fixed",
						top: "0",
						left: "0",
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(0, 0, 0, 0.8)",
						zIndex: "9999",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<button
						onClick={closeForm}
						style={{
							backgroundColor: "black",
							color: "white",
							border: "2px solid white",
							borderRadius: "5px",
							padding: "35px 5px",
							marginRight: "8px",
							cursor: "pointer",
							transition: "0.3s",
						}}
						onMouseOver={(e) => {
							e.target.style.transform = "scale(0.99)";
						}}
						onMouseOut={(e) => {
							e.target.style.transform = "scale(1)";
						}}
					>Х
					</button>
					<iframe
						title="Google Form"
						src="https://docs.google.com/forms/d/e/1FAIpQLSf-NayRTP5PYAImAQ3dkxulg7xRsMPdJgxCU4Cl8Bp9rj8gPg/viewform?embedded=true"
						width="700"
						height="700"
						arginHeight="0"
						style={{
							backgroundColor: "white",
							borderRadius: "10px",
							overflow: "hidden",
						}}
					>
						Loading...
					</iframe>
				</div>
			)}
		</Nav>
	);
};

export default Navbar;
