import img1 from '../../images/FirstDraftImages/1.webp'
import img2 from '../../images/FirstDraftImages/2.webp'
import img3 from '../../images/FirstDraftImages/3.webp'
import img4 from '../../images/FirstDraftImages/4.webp'
import img5 from '../../images/FirstDraftImages/5.webp'
import img6 from '../../images/FirstDraftImages/6.webp'
import img7 from '../../images/FirstDraftImages/7.webp'

export const firstDraftData = [
	{image: img6},
	{image: img7},
	{image: img1},
    {image: img2},
    {image: img3},
	{image: img4},
	{image: img5}
]