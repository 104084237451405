import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import DesignOne from "../../images/FirstDraftImages/mainPage.webp";
import DesignTwo from "../../images/SecondDraftImages/mainPage.webp";

const Section = styled.section`
	width: 100%;
	height: 100%;
	padding: 2rem calc((100vw - 1300px) / 2);
	background-color: #222222;

	@media screen and (max-width: 580px) {
		margin-bottom: -18rem;
	}

	@media screen and (max-width: 375px) {
		margin-bottom: -20rem;
	}
`;

const Container = styled.div`
	height: 100%;
	width: 100%;
	padding: 2rem 1rem;
`;

const Heading = styled.div`
	font-size: 1.5rem;
	padding: 2rem 1rem 0rem 1rem;
	margin-bottom: 40px;
	letter-spacing: 1.5px;

	@media screen and(max-width: 768px) {
		text-align: start;
	}
`;

const InfoRow = styled.div`
	display: flex;
	flex-direction: row;
	padding: 1rem 0rem;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		margin-bottom: 0rem;
	}
`;

const InfoWrap = styled.div`
	padding: 0rem 1rem;
	min-height: 550px;
	height: 100%;

	h2 {
		margin-bottom: 1rem;
		font-weight: 400;
	}

	@media screen and (max-width: 768px) {
		margin-bottom: 0rem;
	}

	@media screen and (max-width: 375px) {
		margin-bottom: -5rem;
	}
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
	max-width: 600px;
	max-height: 400px;
	object-fit: cover;
	margin-bottom: 1rem;
	box-shadow: 0px 20px 30px -25px rgba(255,255,255,0.75);
	transition: 1s;

	&:hover {
		transform: scale(1.01);
	}
`;

const ImageTwo = styled.img`
	width: 100%;
	height: 100%;
	max-width: 600px;
	max-height: 400px;
	object-fit: cover;
	margin-bottom: 1rem;
	margin-top: 120px;
	box-shadow: 0px 20px 30px -25px rgba(255,255,255,0.75);
	transition: 1s;

	&:hover {
		transform: scale(1.01);
	}

	@media screen and (max-width: 768px) {
		margin-top: 0px;
	}
	@media screen and (max-width: 580px) {
		margin-top: -65px;
		margin-bottom: 0rem;
	}
`;

const tilt = keyframes`
  0%, 100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(1deg);
  }
`;

const InfoLink = styled(Link)`
	display: flex;
	align-items: center;
	text-decoration: none;
	color: white;
	width: 190px;
	height: 25px;
	transition: 0.5s;
	letter-spacing: 1.5px;
	font-weight: bold;
	margin-top: 25px;

	p {
		padding-left: 15px;
		transition: 0.5s;
	}

	&:hover {
		animation: none;
		// transform: translateX(3px);
		// transition: 0.5s;
	}

	animation: ${tilt} 2s infinite ease;
`;

const Arrow = styled(FaLongArrowAltRight)`
	margin-left: 10px;
`;

const Listings = () => {
	return (
		<Section>
			<Container>
				<Heading>
					<h1 style={{ color: "white", fontWeight: "400" }}
						data-aos="zoom-out-right"
						data-aos-duration="1500"
						data-aos-delay="250"
						data-aos-once="true"
						data-aos-anchor-placement="center bottom"
					>
						View our newest projects
					</h1>
				</Heading>
				<InfoRow>
					<InfoWrap
						data-aos="zoom-out-up"
						data-aos-duration="1600"
						data-aos-delay="300"
						data-aos-once="true"
						data-aos-anchor-placement="center bottom"
					>
						<Image src={DesignOne} alt="design" style={{ borderRadius: "4px" }} />
						<h2 style={{ color: "#CD853F", fontWeight: 'normal', letterSpacing: '1.2px', marginTop: (window.innerWidth < 580 ? "20px" : "0") }}>Small Apartment Kitchen and Living</h2>
						<InfoLink to="/firstdraft">
							<p>View details</p>
							<Arrow />
						</InfoLink>
					</InfoWrap>
					<InfoWrap
						data-aos="zoom-out-down"
						data-aos-duration="1700"
						data-aos-delay="450"
						data-aos-once="true"
						data-aos-anchor-placement="center bottom"
					>
						<ImageTwo src={DesignTwo} alt="design" style={{ borderRadius: "4px" }} />
						<h2 style={{ color: "#CD853F", fontWeight: 'normal', letterSpacing: '1.2px', marginTop: (window.innerWidth < 580 ? "20px" : "-3px") }}>Top Floor Apartment</h2>
						<InfoLink to="/seconddraft">
							<p>View details</p>
							<Arrow />
						</InfoLink>
					</InfoWrap>
				</InfoRow>
			</Container>
		</Section>
	);
};

export default Listings;